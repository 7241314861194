import React, { FunctionComponent } from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import CompanySection from "../components/sections/CompanySection"
import CompanyRegistrationHero from "../components/sections/CompanyHero"
import Line from "../components/lines/Line"
import EnquireButton from "../components/buttons/Enquirebutton"
import styled from "styled-components"

interface CompanyRegistrationProps {
  title: string
  name: string
  content: string
}

const CompanyRegistration: FunctionComponent<CompanyRegistrationProps> =
  props => {
    const {
      title = "Company Registration",
      name = "Keywords",
      content = "Education",
    } = props
    return (
      <Layout>
        <SEO title={title} />
        <meta name={name} content={content} />
        <CompanySection />
        <LineWrapper>
          <Line />
        </LineWrapper>
        <CompanyRegistrationHero />
        <ButtonWrapper>
          <EnquireButton />
        </ButtonWrapper>
      </Layout>
    )
  }

export default CompanyRegistration

const LineWrapper = styled.div`
  margin: 50px 0 0 0;
  display: grid;
  justify-content: center;
`
const ButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  padding: 0 0 120px 0;
  @media (max-width: 640px) {
    padding: 10px 0 40px 0;
  }
`
