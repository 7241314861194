import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { FunctionComponent } from "react"
import {
  ContentWrapper,
  Description,
  FileWrapper,
  TextWrapperWrapper,
  Title,
  Wrapper,
} from "../../styles/HeroElement"
// import Enquirebutton from "../buttons/Enquirebutton"

interface CompanySectionProps {
  title?: string
  description?: string
}

const CompanySection: FunctionComponent<CompanySectionProps> = props => {
  const {
    title = "Company Registration",
    description = "Register your company and receive all your documentation within only 72 hours. For the special price of ONLY R650!",
  } = props

  const data = useStaticQuery(graphql`
    query CompanyQuery {
      Image: file(relativePath: { eq: "cip.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 10)
        }
      }
    }
  `)
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapperWrapper>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {/* <Enquirebutton /> */}
        </TextWrapperWrapper>
        <FileWrapper>
          <GatsbyImage
            style={styles}
            alt="image"
            image={data.Image.childImageSharp.gatsbyImageData}
          />
        </FileWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default CompanySection
const styles = {
  borderRadius: "20px",
}
