import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { H2, Paragraph } from "../../styles/TextStyles"

interface ComaponyRegistrationHeroProps {
  title?: string
  subtitle?: string
}

const CampanyRegistitrationHero: FunctionComponent<ComaponyRegistrationHeroProps> =
  props => {
    const {
      title = "CIPC Company registration",
      subtitle = "Register your business in record time! Receive the following documents in 72 Hours:",
    } = props
    return (
      <Wrapper>
        <ContentWrapper>
          <TextWrapper>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </TextWrapper>
          <ListWrapper>
            <List>CIPC Registration</List>
            <List>BBBEE Certificate</List>
            <List>Income Tax Number</List>
            <List>SARS Documentation</List>
          </ListWrapper>
        </ContentWrapper>
      </Wrapper>
    )
  }

export default CampanyRegistitrationHero

const Wrapper = styled.div`
  padding: 100px 38px;
`
const ContentWrapper = styled.div`
  display: grid;
  gap: 50px;
`
const Title = styled(H2)`
  font-weight: 700;
  @media (max-width: 640px) {
    font-size: 30px;
    line-height: 130%;
  }
`
const Subtitle = styled(Paragraph)`
  max-width: 400px;
  line-height: 130%;
`
const List = styled.li`
  font-size: 22px;
  font-weight: bold;
`
const ListWrapper = styled.div`
  display: grid;
  gap: 20px;
  justify-content: center;
`
const TextWrapper = styled.div`
  display: grid;
  gap: 20px;
`
